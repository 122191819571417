import {
  ILink,
  ILinkGrouped,
  IImageWithText,
  ILinkMenuMain,
} from '@/types/contentful/contentful';
import { SectionEnum } from '@/types/enums';

export interface IMegaMenuContentCmp {
  groupedLinks: (IImageWithText | ILinkGrouped | ILinkMenuMain)[] | undefined;
  locale: string;
  parentLink?: ILink;
  parentName?: SectionEnum;
  isvisible?: boolean;
}

export function isImageWithText(
  menu: ILinkGrouped | IImageWithText | ILinkMenuMain
): menu is IImageWithText {
  return (menu as IImageWithText).fields?.media !== undefined;
}
