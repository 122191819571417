import React, { useEffect } from 'react';
import Head from 'next/head';
import { Header, Footer, CartDrawer, SnackBar } from '@/components/organisms';
import { Modal } from '@/components/organisms';
import AlertPreview from '@/components/molecules/AlertPreview';
import { ILayoutCmp } from './ILayoutCmp';
import ProductPageMarkup from '@/utils/ProductPageMarkup';
import HomePageMarkup from '@/utils/HomePageMarkup';
import {
  getDataRegisterFromFooter,
  getDataPopupTimerFromFooter,
  getOrderPopupTimerFromFooter,
} from '@/utils/index';
import { useDispatch } from 'react-redux';
import { setOrderPopupTimer } from '@/store/actions/ui';
import { useAppContext } from '@/context/state';
import { IHeaderFields } from '@/types/contentful/contentful';
import { createGlobalStyle, css } from 'styled-components';
import styled from 'styled-components';

type StyleProps = {
  headerPosition: IHeaderFields['position'];
};

const GlobalStyle = createGlobalStyle<StyleProps>`
:root {
	--header-height: 0px;
	${props =>
    props.headerPosition === 'sticky' &&
    css`
      --header-height: 180px;
    `};
 }
 `;

const Main = styled.main`
  padding: clamp(1.5rem, 1.1176rem + 1.5686vw, 3rem) 0px; //24px - 48px
`;

const Layout: React.FC<ILayoutCmp> = props => {
  const {
    children,
    preview,
    layout,
    locales,
    locale,
    pageType,
    productObj,
    currency,
  } = props;
  const { meta, header, footer } = layout;

  const dispatch = useDispatch();
  const context = useAppContext();
  const cartPage = context?.settings ? context.settings['cartPage'] : false;

  let dataRegister = getDataRegisterFromFooter(footer);
  let dataPopupTimer = getDataPopupTimerFromFooter(footer);
  let orderPopupTimer = getOrderPopupTimerFromFooter(footer);

  useEffect(() => {
    if (orderPopupTimer.length > 0) {
      dispatch(setOrderPopupTimer(orderPopupTimer));
    }
  }, []);

  return (
    <div>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png?v=3"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png?v=3"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png?v=3"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg?v=3"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <style
          dangerouslySetInnerHTML={{
            __html: `.async-hide { opacity: 0 !important}`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
						h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
						(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
						})(window,document.documentElement,'async-hide','dataLayer',4000,
						{'${process.env.GTM_ID}':true});`,
          }}></script>
        <script type="text/javascript" src="/js/utils.js?v=30.51"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `				
						if (window?.Mirta?.klaviyo) {
							window.Mirta.klaviyo.setUuidEmail();
						}
						window.gtag({
							originalLocation: window.location.protocol + '//' + window.location.hostname + window.location.pathname + window.location.search
						})					
						`,
          }}></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','${process.env.GTM_ID}');`,
          }}></script>
        <title>{meta.title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content={`${meta.metaDescription}`} />
        {meta.robots && <meta name="robots" content={`${meta.robots}`} />}
        {meta.canonical && <link rel="canonical" href={`${meta.canonical}`} />}
        <script
          defer
          src="https://polyfill.io/v3/polyfill.min.js?features=Intl.NumberFormat,Intl.NumberFormat.~locale.en,Intl.PluralRules.~locale.en"></script>
        <script
          async
          type="text/javascript"
          src={`//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_COMPANY_ID}`}></script>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async></script>
        {pageType == 'homepage'
          ? HomePageMarkup()
          : pageType == 'productPage'
          ? ProductPageMarkup(productObj)
          : ''}

        {pageType == 'homepage' ? (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `{
							"@context": "http://schema.org",
							"@type": "WebSite",
							"url": "https://www.mirta.com/"
						}`,
            }}></script>
        ) : null}

        <link
          rel="preload"
          href="/fonts/futuraExtraBold/FuturaPOTExtraBold_19035.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="true"
        />
        <link
          rel="preload"
          href="/fonts/nbMedium/nbinternationalpromed-webfont.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="true"
        />
      </Head>
      {header && (
        <>
          <GlobalStyle headerPosition={header.position} />
          <Header
            {...header}
            locales={locales}
            locale={locale}
            currency={currency}
          />
        </>
      )}
      {preview ? <AlertPreview /> : null}
      <Main>{children}</Main>
      {!cartPage && (
        <aside>
          <CartDrawer />
        </aside>
      )}
      <Modal dataRegister={dataRegister} dataPopupTimer={dataPopupTimer} />
      <SnackBar />
      {footer && <Footer locale={locale} content={{ ...footer }} />}
    </div>
  );
};

export default Layout;
