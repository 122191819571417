import { QuantityCounter, MinQuantityOrder } from '@/components/atoms';
import styled from 'styled-components';
import { IMinOrderCounterCmp } from './IMinOrderCounterCmp';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;
const MinQuantityOrderContainer = styled.div<IMinQuantityOrderContainer>`
  margin-bottom: 8px;
  margin-right: 0px;
  color: ${props =>
    props.error ? props.theme.paletteDefault.error.main : 'inherit'};
  ${props => props.theme.breakpoints.up('md')} {
    margin-right: 8px;
    margin-bottom: 0px;
  }
`;

interface IMinQuantityOrderContainer {
  error: boolean;
}

const MinOrderCounter: React.FC<IMinOrderCounterCmp> = props => {
  const {
    error,
    minimum_order_quantity,
    template,
    debounce,
    changeQuantityHandler,
    quantity,
  } = props;

  return (
    <Container>
      {minimum_order_quantity > 1 && (
        <MinQuantityOrderContainer error={error}>
          <MinQuantityOrder
            error={error}
            minimum_order_quantity={minimum_order_quantity}
          />
        </MinQuantityOrderContainer>
      )}
      <QuantityCounter
        template={template}
        debounce={debounce}
        changeQuantityHandler={changeQuantityHandler}
        quantity={quantity}
      />
    </Container>
  );
};

export default MinOrderCounter;
