import React from 'react';
import dynamic from 'next/dynamic';
import { RootState } from '@/store/store';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '@/store/actions/ui';
import { Dialog } from '@/components/atoms';
import { IModal } from '@/interfaces/index';

const Modals: { [key: string]: any } = {
  register: dynamic(() => import(`@/components/molecules/ModalRegister`)),
  login: dynamic(() => import(`@/components/molecules/ModalLogin`)),
  cartInvalid: dynamic(() => import(`@/components/molecules/ModalCartInvalid`)),
  checkoutNotAllowed: dynamic(
    () => import(`@/components/molecules/ModalCheckoutNotAllowed`)
  ),

  popuptimer: dynamic(() => import(`@/components/molecules/ModalPopupTimer`)),
  logReg: dynamic(() => import(`@/components/molecules/ModalLogReg`)),
};

const Modal: React.FC<IModal> = props => {
  const { dataRegister, dataPopupTimer } = props;

  const modalType = useSelector(
    (state: RootState) => state.uiReducer.modalType
  );
  const modalOpen = useSelector(
    (state: RootState) => state.uiReducer.modalOpen
  );

  const dispatch = useDispatch();
  const closeModalHandler = () => {
    dispatch(closeModal());
  };

  const DynamicContent = Modals[modalType] || null;

  return DynamicContent ? (
    <Dialog open={modalOpen || false} onClose={closeModalHandler}>
      <DynamicContent
        onClose={closeModalHandler}
        dataRegister={dataRegister}
        dataPopupTimer={dataPopupTimer}
      />
    </Dialog>
  ) : null;
};

export default Modal;
