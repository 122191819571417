import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Drawer, Button, Backdrop } from '@/components/atoms';
import { CartItem, CartXsell, CartNote, Price } from '@/components/molecules';
import { ICart } from '@/interfaces/index';
import { useSelector, useDispatch } from 'react-redux';
import { toggleCart, updateCartNote } from '@/store/actions/cart';
import { openModal } from '@/store/actions/ui';
import CloseIcon from '../../../public/asset/closeIcon.svg';
import { checkoutMultipassApi } from '@/lib/api.checkout';
import { RootState } from '@/store/store';
import { useRouter } from 'next/router';
import { drawerPreventScroll } from '@/utils/index';
import useBreakpoint from '@/hooks/useBreakpoint';
import { useTranslation } from 'next-i18next';
import { useAppContext } from '@/context/state';
import { isHandleInCart } from '@/utils/Cart';

const WrapperCart = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 85vw;
  max-width: 380px;
  ${props => props.theme.breakpoints.up('md')} {
    max-height: 100vh;
    height: auto;
    background: ${props => props.theme.paletteDefault.white};
  }
`;

const UL = styled.ul`
  width: 100%;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${props => props.theme.paletteDefault.grey1};
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  height: 100%;
  overflow: auto;
`;

const EmptyCartBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  height: 100%;
  min-height: 100px;
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	padding: 16px;
	border-top: 1px solid ${props => props.theme.paletteDefault.grey1}
	flex-direction: column;
	align-items: center;
`;

const CartBackdrop = styled(Backdrop)`
  touch-action: none;
  z-index: 1000000001;
`;

const CheckoutWrapper = styled.span`
  margin-right: 5px;
`;

const MinAmountWarning = styled.span`
  margin-top: 10px;
  font-size: 12px;
  text-transform: uppercase;
  color: ${props => props.theme.paletteDefault.error.main};
`;

export interface ILineItems {
  quantity: number;
  variantId: string;
}

const CartDrawer: React.FC<ICart> = () => {
  const { t } = useTranslation('common');
  const context = useAppContext();
  const breakpoints = useBreakpoint();
  const router = useRouter();

  const cartOpenState = useSelector(
    (state: RootState) => state.cartReducer.isDrawerOpen
  );
  const cart = useSelector((state: RootState) => state.cartReducer.cart);
  const isCartLoaded = useSelector(
    (state: RootState) => state.cartReducer.cartLoaded
  );

  const customerAccessToken = useSelector(
    (state: RootState) => state.userReducer.accessToken
  );

  const customer = useSelector(
    (state: RootState) => state.userReducer.customer
  );

  const isLogged = useSelector(
    (state: RootState) => state.userReducer.isLogged
  );
  const isApproved = useSelector(
    (state: RootState) => state.userReducer.isApproved
  );

  const currency = useSelector(
    (state: RootState) => state.currencyReducer.currency
  );

  let checkoutMinimumAmount: number;
  let checkoutOnlyForApprovedUsers: boolean;
  if (context?.settings) {
    checkoutMinimumAmount = context?.settings.checkoutMinimumAmount as number;
    checkoutOnlyForApprovedUsers =
      context?.settings.checkoutOnlyForApprovedUsers;
  }

  const dispatch = useDispatch();
  const [cartLoaded, setCartLoaded] = useState(false);
  const [offsetX, setOffsetX] = useState(0);

  useEffect(() => {
    setCartLoaded(cartLoaded);
  }, [isCartLoaded]);

  const onToggleCart = () => {
    dispatch(toggleCart(false));
  };

  const onResize =
    //debounce(
    () => {
      if (window.innerWidth > 1280) {
        setOffsetX((window.innerWidth - 1280) / 2);
      } else {
        setOffsetX(0);
      }
    };
  //, 0);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const minAmountReached = () => {
    return parseFloat(cart?.totalPrice) >= checkoutMinimumAmount;
  };

  const multipassCheckout = (e: any) => {
    e.stopPropagation();

    if (process.env.NEXT_PUBLIC_B2B === '1') {
      if (!isLogged) {
        dispatch(toggleCart(false));
        dispatch(openModal('logReg', '', 'register'));
        return false;
      } else {
        if (!minAmountReached()) {
          return false;
        }

        if (checkoutOnlyForApprovedUsers && !isApproved) {
          dispatch(toggleCart(false));
          dispatch(openModal('checkoutNotAllowed'));
          return false;
        }
      }
    }

    let cartUrl = '';

    if (cart && cart.webUrl) {
      cartUrl = cart.webUrl.replace(
        process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN,
        process.env.NEXT_PUBLIC_CHECKOUT_DOMAIN
      );
    }

    if (customerAccessToken && customer) {
      const btoa = window.btoa(
        JSON.stringify({
          _: customer.id,
          checkoutUrl: cartUrl,
        })
      );
      checkoutMultipassApi(btoa)
        .then(multipassToken => {
          if (multipassToken && multipassToken.m) {
            router.push(
              process.env.NEXT_PUBLIC_CHECKOUT_DOMAIN +
                '/account/login/multipass/' +
                multipassToken.m
            );
          } else {
            router.push(cartUrl);
          }
        })
        .catch(() => {
          router.push(cartUrl);
        });
    } else {
      router.push(cartUrl);
    }
  };

  useEffect(() => {
    drawerPreventScroll(document, window, cartOpenState);
  }, [cartOpenState]);

  if (!currency) {
    return null;
  }

  const saveNote = (cartNote: string) => {
    dispatch(updateCartNote(cartNote, customer));
  };

  let lineItems = null;
  let cartXsellHandles: string[] = [];

  if (cart && cart.lineItems) {
    lineItems = cart.lineItems.edges.map(lineItem => {
      const cartXsellHandle = lineItem?.node?.variant?.product?.metafield;
      if (
        cartXsellHandle &&
        cartXsellHandle.value != '-' &&
        !isHandleInCart(cart.lineItems.edges, cartXsellHandle.value) &&
        cartXsellHandles.indexOf(cartXsellHandle.value) === -1
      ) {
        cartXsellHandles.push(cartXsellHandle.value);
      }
      return (
        <CartItem
          key={lineItem.node.id}
          cartPage={false}
          lineItem={lineItem.node}
        />
      );
    });
  }

  return (
    <>
      <CartBackdrop open={cartOpenState} onClick={onToggleCart} />
      <Drawer
        open={cartOpenState}
        anchor={breakpoints === 'xs' || breakpoints === 'sm' ? 'right' : 'top'}
        onClose={onToggleCart}
        transitionDuration={400}
        variant="persistent"
        classes={
          breakpoints !== 'xs' && breakpoints !== 'sm'
            ? {
                paper: {
                  right: offsetX + 'px',
                  left: 'inherit',
                  maxWidth: '380px',
                  width: '100%',
                },
              }
            : null
        }>
        <WrapperCart className="drawerContent">
          <Header>
            <div> {t('cart.label').toUpperCase()}</div>
            <CloseIcon
              style={{
                zoom: '0.7',
                marginRight: '-8px',
                cursor: 'pointer',
              }}
              onClick={onToggleCart}
            />
          </Header>
          {lineItems && lineItems.length > 0 ? (
            <React.Fragment>
              <Body>{<UL>{lineItems}</UL>}</Body>
              <CartXsell
                cartPage={false}
                currency={currency}
                cartXsellHandles={cartXsellHandles}
              />
              {cart && (
                <CartNote
                  cartPage={false}
                  note={cart.note}
                  saveNote={saveNote}
                />
              )}
              {cart && cart.webUrl ? (
                <Footer>
                  <Button onClick={multipassCheckout}>
                    <CheckoutWrapper>{`${t(
                      'cart.checkout'
                    )} `}</CheckoutWrapper>
                    <Price
                      template="medium"
                      prices={[
                        {
                          amount: cart?.totalPrice.amount,
                          currencyCode: currency.isoCode,
                        },
                      ]}
                      currency={currency}
                    />
                  </Button>

                  {process.env.NEXT_PUBLIC_B2B === '1' &&
                    isLogged &&
                    !minAmountReached() && (
                      <MinAmountWarning>
                        {t('cart.min-amount-b2b')}
                      </MinAmountWarning>
                    )}
                </Footer>
              ) : null}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <EmptyCartBody>{<div>{t('cart.empty-cart')}</div>}</EmptyCartBody>
              <Footer>
                <Button onClick={onToggleCart}>{t('cta.shop-2')}</Button>
              </Footer>
            </React.Fragment>
          )}
        </WrapperCart>
      </Drawer>
    </>
  );
};

export default CartDrawer;
