import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ICartItemImageCmp } from './ICartItemImageCmp';
import { toggleCart } from '@/store/actions/cart';

interface StyleProps {
  unavailable?: boolean;
}

const LineItemImg = styled.div<StyleProps>`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.paletteDefault.error.main};
  padding: ${props => (props.unavailable ? '20px' : '0')};
`;

const CartItemImage: React.FC<ICartItemImageCmp> = ({ title, variant }) => {
  const dispatch = useDispatch();

  return (
    <LineItemImg
      unavailable={!variant?.image?.src}
      onClick={() => {
        dispatch(toggleCart(false));
      }}>
      <Link
        href={{
          pathname: '/products/[handle]',
          query: { handle: variant?.product?.handle },
        }}
        locale={'en'}>
        <a>
          <img
            src={variant?.image?.src || '/asset/product_not_available.png'}
            alt={`${title} product shot`}
          />
        </a>
      </Link>
    </LineItemImg>
  );
};

export default CartItemImage;
