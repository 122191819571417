import React, { useState } from 'react';
import { CartItemRow, CartItemDrawer } from '@/components/molecules';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import {
  updateCart,
  addIdWithNoMinQty,
  deleteIdWithNoMinQty,
} from '@/store/actions/cart';
import { removeFromCart } from '@/store/actions/cart';
import { ICartItemCmp } from './ICartItemCmp';
import { findVariantInCart } from '@/utils/Cart';

export const CartItem: React.FC<ICartItemCmp> = ({ lineItem, cartPage }) => {
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const currency = useSelector(
    (state: RootState) => state.currencyReducer.currency
  );
  const customer = useSelector(
    (state: RootState) => state.userReducer.customer
  );
  const cart = useSelector((state: RootState) => state.cartReducer.cart);

  if (!currency) {
    return null;
  }

  const changeQuantityHandler = (debouncedSearchTerm: number) => {
    let itemInCart;
    if (cart && lineItem?.variant?.id) {
      itemInCart = findVariantInCart(
        cart.lineItems.edges,
        lineItem?.variant?.id
      );
    }

    if (
      lineItem?.variant?.product?.minimum_order_quantity?.value !== undefined
    ) {
      if (
        debouncedSearchTerm <
        parseInt(lineItem?.variant?.product?.minimum_order_quantity?.value)
      ) {
        dispatch(addIdWithNoMinQty(lineItem?.variant?.id));
        setError(true);
      } else {
        dispatch(deleteIdWithNoMinQty(lineItem?.variant?.id));
        setError(false);
      }
    }

    if (itemInCart && itemInCart?.node?.quantity !== debouncedSearchTerm) {
      return dispatch(
        updateCart(lineItem, debouncedSearchTerm, currency, customer)
      );
    }
  };

  const removeItemHandler = () => {
    dispatch(removeFromCart(lineItem, currency, customer));
    if (lineItem && lineItem.variant && lineItem.variant.id) {
      dispatch(deleteIdWithNoMinQty(lineItem?.variant?.id));
    }
  };
  let { variant } = lineItem;
  let selectedOptionsConcat = '';

  if (variant) {
    const selectedOptions = variant.selectedOptions;
    const selectedColor = selectedOptions.find(option => {
      return option.name.toLowerCase() === 'color';
    });
    const selectedSize = selectedOptions.find(option => {
      return option.name.toLowerCase() === 'size';
    });
    const otherOptions = selectedOptions.filter(option => {
      return !['color', 'size'].includes(option.name.toLowerCase());
    });

    selectedOptionsConcat = `${selectedColor?.value || ''}${
      selectedSize?.value ? ' - ' + selectedSize?.value : ''
    }${otherOptions.reduce((acc, curr) => {
      return acc + ' - ' + curr?.value;
    }, '')}`;
  }

  return (
    <>
      {cartPage ? (
        <CartItemRow
          cartPage={true}
          lineItem={lineItem}
          currency={currency}
          changeQuantityHandler={changeQuantityHandler}
          removeItemHandler={removeItemHandler}
          selectedOptions={selectedOptionsConcat}
          error={error}></CartItemRow>
      ) : (
        <CartItemDrawer
          cartPage={false}
          lineItem={lineItem}
          currency={currency}
          changeQuantityHandler={changeQuantityHandler}
          removeItemHandler={removeItemHandler}
          selectedOptions={selectedOptionsConcat}></CartItemDrawer>
      )}
    </>
  );
};

export default CartItem;
