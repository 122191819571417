import React from 'react';
import { GroupLinks } from '@/components/molecules';
import styled, { css } from 'styled-components';
import { HeadingGroupLinks, Typography } from '@/components/atoms';
import { IMegaMenuContent } from '@/interfaces/index';
import ImageWithRatio from '@/components/atoms/ImageWithRatio';
import Link from 'next/link';
import { makeLink } from '@/utils/Link';
import { IMegaMenuContentCmp, isImageWithText } from './IMegaMenuContentCmp';

const Container = styled.div<IMegaMenuContent>`
  width: 100%;
  margin: 40px auto;

  ${props =>
    props.parentName === 'footer' &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      ${props => props.theme.breakpoints.up('sm')} {
        flex-direction: row;
        margin: 40px 16px 48px 0;
      }
      ${props => props.theme.breakpoints.up('md')} {
        flex-direction: row;
        margin: 40px 32px 48px 0;
      }
    `}

  ${props =>
    props.parentName !== 'footer' &&
    css`
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 24px;
    `}
` as React.ElementType;

const Column = styled.div`
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: 0;
  }
  & a {
    color: inherit;
  }
`;

const CustomTypography = styled(Typography)`
  letter-spacing: 1px;
  font-size: 1rem;
  line-height: 20px;
  text-align: left;
`;

const ImgCont = styled.figure`
  &.v,
  &.h {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0px 0 0;
  }
  &.v {
    width: 220px;
  }
  &.h {
    width: 300px;
  }
` as React.ElementType;

const MegaMenuContent: React.FC<IMegaMenuContentCmp> = props => {
  const { groupedLinks, locale, parentLink, parentName } = props;
  let content = null;

  if (groupedLinks && groupedLinks.length > 0) {
    content = groupedLinks.map((menu, index) => {
      let tmpl = null;

      if (isImageWithText(menu)) {
        let extraClass =
          menu.fields?.media?.fields?.file?.details?.image?.width &&
          menu.fields?.media?.fields?.file?.details?.image?.height
            ? menu.fields.media.fields.file.details.image.width >
              menu.fields.media.fields.file.details.image.height
              ? 'h'
              : 'v'
            : null;
        let url = null;

        if (
          menu &&
          menu.fields &&
          menu.fields.link &&
          menu.fields.link.length > 0 &&
          menu.fields?.link[0]?.fields?.href
        ) {
          url = makeLink(
            menu.fields?.link[0]?.fields?.type,
            menu.fields?.link[0]?.fields?.href,
            null
          );
        }

        let imgWithTextBelow = (
          <>
            <ImgCont className={extraClass}>
              {menu.fields?.media?.fields?.file?.details?.image?.width &&
                menu.fields?.media?.fields?.file?.details?.image?.height && (
                  <ImageWithRatio
                    ratio={{
                      width: menu.fields.media.fields.file.details.image.width,
                      height:
                        menu.fields.media.fields.file.details.image.height,
                    }}
                    media={menu.fields.media}
                    alt={menu.fields.headingText || ''}
                  />
                )}
              {menu.fields?.headingText && (
                <figcaption
                  style={{
                    marginTop: '16px',
                  }}>
                  <CustomTypography>{menu.fields.headingText}</CustomTypography>
                </figcaption>
              )}
            </ImgCont>
          </>
        );
        tmpl = (
          <>
            {url ? (
              <Link href={url} locale={locale} shallow={false} passHref>
                <a>{imgWithTextBelow}</a>
              </Link>
            ) : (
              imgWithTextBelow
            )}
          </>
        );
      } else {
        tmpl = (
          <>
            {menu?.fields?.heading ? (
              <HeadingGroupLinks
                heading={menu.fields.heading}
                link={menu.fields.linkedTo ? menu.fields.linkedTo : null}
                locale={locale}
                parentLink={parentLink}
                parentName={parentName}
              />
            ) : null}
            {menu.fields ? (
              <GroupLinks
                links={menu.fields.link}
                heading={menu.fields.heading}
                locale={locale}
                parentLink={parentLink}
                parentName={parentName}
              />
            ) : null}
          </>
        );
      }

      return <Column key={`${menu.sys.id}-${index}`}>{tmpl}</Column>;
    });
  }
  return <Container parentName={parentName}>{content}</Container>;
};

export default MegaMenuContent;
