import React from 'react';
import MuiDrawer, { DrawerProps } from '@material-ui/core/Drawer';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
const Content = styled.div`
  height: 100%;
`;

interface IDrawer extends DrawerProps {}

const Drawer: React.FC<IDrawer> = props => {
  const useStyles = makeStyles(props.classes || {});
  const classes = useStyles();

  return (
    <MuiDrawer
      open={props.open}
      anchor={props.anchor}
      onClose={props.onClose}
      variant={props.variant}
      transitionDuration={props.transitionDuration}
      classes={classes}>
      <Content anchor={props.anchor}>{props.children}</Content>
    </MuiDrawer>
  );
};

export default Drawer;
