import { actionTypes } from './actionTypes';
import { IBannerCountdown } from '@/interfaces/IBannerCountdown';

interface IPayload {
  code: string | null;
  bannerCountdown: IBannerCountdown;
}

export interface SetCountDownBarAction {
  type: actionTypes.SET_COUNTDOWN_BAR;
  payload: IPayload;
}

export interface SetCountDownBarDestroyAction {
  type: actionTypes.SET_COUNTDOWN_BAR_DESTROY;
}

export const setCountDownBar = (payload: IPayload): SetCountDownBarAction => {
  return {
    type: actionTypes.SET_COUNTDOWN_BAR,
    payload: payload,
  };
};

export const setCountDownBarDestroy = (): SetCountDownBarDestroyAction => {
  return {
    type: actionTypes.SET_COUNTDOWN_BAR_DESTROY,
  };
};
