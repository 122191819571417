import React, { useEffect } from 'react';
import Countdown from 'react-countdown';
import { Typography } from '@/components/atoms';
import styled from 'styled-components';
import { getCookie, setCookie } from '@/utils/index';
import { useDispatch } from 'react-redux';
import { setCountDownBarDestroy } from '@/store/actions/countdownbar';

const Container = styled.div`
  background-color: ${props => props.theme.paletteDefault.grey1};
  padding: 24px;
  text-align: center;
`;

const Time = styled.span`
  letter-spacing: 1px;
  min-width: 40px;
  display: inline-block;
`;

interface ICountdownBar {
  code: string;
  fields: {
    duration: number;
    enabled: boolean;
    text: string;
  };
}

const CountdownBar: React.FC<ICountdownBar> = props => {
  const { code } = props;
  const { text } = props.fields;

  const duration = getCookie('promoBannerDuration')
    ? parseInt(getCookie('promoBannerDuration'), 10)
    : parseInt(props.fields.duration, 10);

  const dispatch = useDispatch();

  useEffect(() => {
    setCookie('isPromoBannerVisibile', '1', 365);
  }, []);

  const renderer = (props: any) => {
    const { completed, total, formatted } = props;

    setCookie('promoBannerDuration', total, 365);

    if (completed) {
      dispatch(setCountDownBarDestroy());
      return null;
    } else {
      return (
        <span>
          <Typography datatype="body_1">
            {code} {text} <Time>{formatted.hours}h</Time>{' '}
            <Time>{formatted.minutes}m </Time>
            <Time>{formatted.seconds}s</Time>
          </Typography>
        </span>
      );
    }
  };
  return (
    <Container>
      <Countdown
        zeroPadTime={2}
        zeroPadDays={2}
        date={Date.now() + duration}
        renderer={renderer}
      />
    </Container>
  );
};

export default CountdownBar;
