import { useRouter } from 'next/router';

import { ProductWithVariants } from '@/lib/models/types/product';

const ProductPageMarkup = (productObj?: ProductWithVariants) => {
  const lineItem = productObj?.variants?.at(0);
  const router = useRouter();
  let priceUSD;
  let currencyParam = '';

  if (router?.query?.currency) {
    currencyParam = '?currency=' + router.query.currency;
  }

  if (lineItem && lineItem.prices && lineItem.prices.length > 0) {
    priceUSD = lineItem.prices.find(node => {
      return node.currencyCode === 'USD';
    });
  }

  return (
    <script
      className="productMarkup"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `{
								"@context": "https://schema.org/",
								"@type": "Product",
								"name": "${productObj?.title} - Italian ${
          productObj?.productMetafields.subCategory
        }, ${productObj?.productMetafields.colorDescription}, ${
          productObj?.productMetafields.materialDescription
        }
				}, 100% Made in Italy by ${productObj?.vendor}",
								"description": "${productObj?.productDescription?.description} ${
          productObj?.productDescription?.details
        } ${productObj?.productDescription?.shippingAndReturns} ${
          productObj?.productDescription?.size
        }",
								"url": "${process.env.BASE_DOMAIN_URL}${router.asPath}",
								"image": "${lineItem?.image}",
								"brand": "${productObj?.vendor}",
								"sku": "${lineItem?.sku}",
								"color": "${lineItem?.color}",
								"material": "${productObj?.productMetafields.materialDescription}",
								"offers": {
									"@type": "Offer",
									"url": "${process.env.BASE_DOMAIN_URL}${router.asPath}${currencyParam}",
									"priceCurrency": "${priceUSD?.currencyCode}",
									"price": "${priceUSD?.amount}",
									"availability": "${
                    productObj?.availability
                      ? productObj?.availability
                      : 'https://schema.org/OutOfStock'
                  }",
									"itemCondition": "https://schema.org/NewCondition"
								}
							}`,
      }}
    />
  );
};

export default ProductPageMarkup;
