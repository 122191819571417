import styled from 'styled-components';
import { IMinQuantityOrderCmp } from './IMinQuantityOrderCmp';
import { ErrorOutline } from '@styled-icons/material';
import { useTranslation } from 'next-i18next';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MinQty = styled.div<IMinQty>`
  color: ${props =>
    props.error ? props.theme.paletteDefault.error.main : 'inherit'};
  font-size: 12px;
`;

const CustomErrorOutline = styled(ErrorOutline)`
  color: ${props => props.theme.paletteDefault.error.main};
  margin-right: 4px;
`;

interface IMinQty {
  error: boolean;
}

const MinQuantityOrder: React.FC<IMinQuantityOrderCmp> = props => {
  const { error, minimum_order_quantity } = props;
  const { t } = useTranslation(['product']);
  return (
    <Container>
      {error && <CustomErrorOutline size={18} />}
      <MinQty error={error}>
        {t('product:min_order_quantity')} {minimum_order_quantity}
      </MinQty>
    </Container>
  );
};

export default MinQuantityOrder;
