import React from 'react';
import { useTheme } from 'styled-components';

const IconMinus: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const theme = useTheme();
  const color1 = theme.icon.color1;
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="m20.28,10.79H3.72c-.08,0-.13.03-.15.04v2.37s.02-.01.02-.01c.02,0,.06.01.12.01h16.57c.08,0,.13-.03.15-.04v-2.37s-.06,0-.15,0Z"
        fill={color1}
      />
    </svg>
  );
};

export default IconMinus;
