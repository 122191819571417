import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import { makeLink } from '@/utils/Link';
import { useAppContext } from '@/context/state';
import { ILinkItemCmp } from './ILinkItemCmp';
import { SectionEnum } from '@/types/enums';
import { Typography, IconDoubleArrow } from '@/components/atoms';

const styleMap = {
  [SectionEnum.FOOTER]: `
			// font-size: 16px;
		 	// text-transform: none;
			// line-height: 24px;
      // color: inherit;
		`,
};

const Container = styled.span<ILinkItemCmp>`
  color: ${props =>
    props.isHighlight ? props.theme.typog.highlightColor : 'inherit'};
  & a {
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    &.fwsmall {
      font-weight: 200;
      letter-spacing: 1px;
    }
    ${props =>
      props.isheading &&
      css`
        font-size: 14px;
      `}
    ${props =>
      props.template === 'mainmenu' &&
      css`
        font-size: clamp(0.875rem, 0.8113rem + 0.2614vw, 1.125rem);
        line-height: 150%;
        padding: 16px;
        display: flex;
        font-weight: 700;
        letter-spacing: 1px;
        &:hover {
          text-decoration: underline;
          &::before {
            transition: transform 0.3s;
            transform-origin: 0% 50%;
            transform: scale3d(1, 1, 1);
          }
        }
        &::before {
          position: absolute;
          width: 100%;
          height: 2px;
          background: ${props => props.theme.typog.color};
          top: 100%;
          left: 0;
          pointer-events: none;
          content: '';
          transform-origin: 100% 50%;
          transform: scale3d(0, 1, 1);
          z-index: 999;
        }
      `}
      ${props =>
      props.template === 'mainmenu' &&
      props.isHighlight &&
      css`
        &::before {
          background: ${props => props.theme.typog.highlightColor};
        }
      `};
    ${props => (props.parentName ? styleMap[props.parentName] : '')}
  }
` as React.ElementType;

const LinkItem: React.FC<ILinkItemCmp> = props => {
  const { locale, template, parentLink, isheading, parentName, showArrow } =
    props;
  const context = useAppContext();
  const pageType = context?.pageType;

  let url = null;

  if (props.fields?.href) {
    url = makeLink(
      props.fields?.type,
      props.fields?.href,
      parentLink ? parentLink.fields.href : null
    );
  }

  let shallow = false;

  if (url && url.indexOf('/collections') === 0 && pageType === 'collection') {
    shallow = true;
  }

  const onLinkSelected = () => {
    var event = new CustomEvent('menuClick', {
      detail: {},
    });
    document.dispatchEvent(event);
  };

  return url ? (
    <Container
      isheading={isheading}
      template={template}
      parentName={parentName}
      isHighlight={!!props?.fields?.highlight}>
      {props.fields?.target === '_blank' ? (
        <Typography>
          <a
            target={props.fields?.target}
            href={url}
            className={isheading ? 'smalltitle' : 'fwsmall'}>
            {props.fields?.text}
            {showArrow && (
              <IconDoubleArrow
                width={11}
                height={27}
                style={{ marginLeft: '2px' }}
              />
            )}
          </a>
        </Typography>
      ) : (
        <Typography>
          <Link href={url} locale={locale} shallow={shallow} passHref>
            <a
              onClick={onLinkSelected}
              className={isheading ? 'smalltitle' : 'fwsmall'}>
              {props.fields?.text}
              {showArrow && (
                <IconDoubleArrow
                  width={11}
                  height={27}
                  style={{ marginLeft: '2px' }}
                />
              )}
            </a>
          </Link>
        </Typography>
      )}
    </Container>
  ) : null;
};

export default LinkItem;
