import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { Price } from '@/components/molecules';
import { QuantityCounter, RemoveItem, CartItemImage } from '@/components/atoms';
import { ICartItemDrawerCmp } from '../ICartItemCmp';

const Li = styled.li`
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  ${props => props.theme.breakpoints.up('mobileM')} {
    margin-bottom: 10px;
  }
`;

const LineItemInfo = styled.div`
  margin-bottom: 16px;
`;

const LineItemText = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const LineItemActionsCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 3px;
  ${props => props.theme.breakpoints.up('mobileM')} {
    flex-direction: row;
    align-items: center;
  }
`;

const LineItemCont = styled.div`
  margin-left: 16px;
  width: calc(100% - 150px);
`;

const LineItemNotAvalaibleText = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  color: ${props => props.theme.paletteDefault.error.main};
`;

const QuantityContainer = styled.div`
  width: 60%;
  margin-bottom: 8px;
  max-width: 80px;
  ${props => props.theme.breakpoints.up('mobileM')} {
    width: 50%;
  }
  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: 0px;
  }
`;

const CartItemImageWrapper = styled.div`
  margin-bottom: 5px;
  max-width: 150px;
`;

export const CartItemDrawer: React.FC<ICartItemDrawerCmp> = ({
  lineItem,
  currency,
  selectedOptions,
  changeQuantityHandler,
  removeItemHandler,
}) => {
  const { t } = useTranslation('common');
  if (!lineItem) {
    return null;
  }
  let { variant, title, quantity } = lineItem;

  let totalPrice = variant?.price?.amount * quantity;

  return (
    <Li>
      <CartItemImageWrapper>
        <CartItemImage title={title} variant={variant}></CartItemImage>
      </CartItemImageWrapper>

      <LineItemCont>
        <div className="Line-item__content-row">
          <LineItemInfo>
            <LineItemText>{title?.toUpperCase()}</LineItemText>
            {variant ? (
              <>
                <LineItemText>{selectedOptions}</LineItemText>
                {currency && (
                  <Price
                    template="medium"
                    prices={[
                      { amount: totalPrice, currencyCode: currency.isoCode },
                    ]}
                    currency={currency}></Price>
                )}
              </>
            ) : (
              <LineItemNotAvalaibleText>
                {t('cart.unavailable-product')}
              </LineItemNotAvalaibleText>
            )}
          </LineItemInfo>

          <LineItemActionsCont>
            {variant && (
              <QuantityContainer>
                <QuantityCounter
                  template="small"
                  debounce={true}
                  changeQuantityHandler={changeQuantityHandler}
                  quantity={quantity}
                />
              </QuantityContainer>
            )}
            <RemoveItem removeItemHandler={removeItemHandler} />
          </LineItemActionsCont>
        </div>
      </LineItemCont>
    </Li>
  );
};

export default CartItemDrawer;
