import {
  CheckoutFragment,
  CheckoutFragment_lineItems_edges,
} from '@/types/shopify/CheckoutFragment';

export const isCartValid = (cart: CheckoutFragment | null) => {
  if (cart?.lineItems?.edges && cart.lineItems.edges.length > 0) {
    let foundInvalidElement = cart.lineItems.edges.find(lineItem => {
      return !lineItem.node.variant;
    });

    return !foundInvalidElement;
  } else {
    return true;
  }
};

export const isHandleInCart = (
  cartItems: CheckoutFragment_lineItems_edges[],
  handle: string
) => {
  let isFound = false;

  if (cartItems && cartItems.length > 0) {
    isFound = cartItems.some(
      cartItem => cartItem.node?.variant?.product?.handle === handle
    );
  }

  return isFound;
};

export const findVariantInCart = (
  cartItems: CheckoutFragment_lineItems_edges[],
  variantId: string
): CheckoutFragment_lineItems_edges | null => {
  let foundItem: CheckoutFragment_lineItems_edges | undefined;
  if (variantId && cartItems && cartItems.length > 0) {
    foundItem = cartItems.find(currentItem => {
      if (currentItem?.node?.variant?.id) {
        return currentItem.node.variant.id === variantId;
      }
    });
  }

  return foundItem || null;
};
