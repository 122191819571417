import React from 'react';
import styled from 'styled-components';
import { LinkItem } from '@/components/atoms';
import { IGroupLinksCmp } from './IGroupLinksCmp';
import { SectionEnum } from '@/types/enums';

const styleMap = {
  [SectionEnum.FOOTER]: 'margin-bottom: 8px;',
};

const ContainerUl = styled.ul`
  display: flex;
  list-style-type: none;
  flex-direction: column;
` as React.ElementType;

const SingleLi = styled.li<Partial<IGroupLinksCmp>>`
  margin: 0;
  text-align: left;
  margin-bottom: 12px;
  &:hover {
    text-decoration: underline;
  }
  ${props => (props.parentName ? styleMap[props.parentName] : '')}
`;

const GroupLinks: React.FC<IGroupLinksCmp> = props => {
  const { links, locale, parentLink, parentName } = props;
  let groupLinks = null;
  if (links && links.length > 0) {
    groupLinks = links.map((link, index) => {
      return (
        <SingleLi key={`${link.sys.id}-${index}`} parentName={parentName}>
          <LinkItem
            {...link}
            locale={locale}
            parentLink={parentLink}
            parentName={parentName}
          />
        </SingleLi>
      );
    });
  }

  return (
    <>
      <ContainerUl>{groupLinks}</ContainerUl>
    </>
  );
};

export default GroupLinks;
