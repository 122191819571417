import React from 'react';
import dynamic from 'next/dynamic';

import {
  IArtisanComponent,
  IButton,
  IArtisanCarousel,
  IProductCarousel,
  IExternalApps,
  IForm,
  IImageWithTextBlock,
  INewsletter,
  IPopupTimer,
  IRegion,
  ISettings,
  ISlider,
  IVideo,
  IParagraph,
  IAnnouncementBar,
  IAppBar,
  IPromoBar,
  IIframe,
} from '@/types/contentful/contentful';

import { ILocales } from '@/types/index';
import { Product } from '@/lib/models/types/product';
import { CurrencyCode } from '@/types/shopify/globalTypes';

const Components: { [key: string]: any } = {
  slider: dynamic(() => import(`@/components/organisms/Slider`)),
  productCarousel: dynamic(
    () => import(`@/components/organisms/ProductCarousel`)
  ),
  announcementBar: dynamic(
    () => import(`@/components/molecules/AnnouncementBar`)
  ),
  appBar: dynamic(() => import(`@/components/molecules/CustomAppBar`)),
  imageWithTextBlock: dynamic(
    () => import(`@/components/organisms/ImageWithTextBlock`)
  ),
  externalApps: dynamic(() => import(`@/components/organisms/ExternalApps`)),
  newsletter: dynamic(() => import(`@/components/organisms/Newsletter`)),
  video: dynamic(() => import(`@/components/organisms/Video`)),
  region: dynamic(() => import(`@/components/molecules/ArtisanRegion`)),
  artisan: dynamic(() => import(`@/components/organisms/Artisan`)),
  artisanList: dynamic(() => import(`@/components/organisms/ArtisanList`)),
  artisanCarousel: dynamic(
    () => import(`@/components/organisms/ArtisanCarousel`)
  ),
  artisanProducts: dynamic(
    () => import(`@/components/organisms/ArtisanProducts`)
  ),
  magazineList: dynamic(() => import(`@/components/organisms/MagazineList`)),
  popupTimer: dynamic(() => import(`@/components/organisms/PopupTimer`)),
  paragraph: dynamic(() => import(`@/components/molecules/Paragraph`)),
  text: dynamic(() => import(`@/components/molecules/Paragraph`)),
  button: dynamic(() => import(`@/components/organisms/Btn`)),
  wishlist: dynamic(() => import(`@/components/organisms/Wishlist`)),
  cart: dynamic(() => import(`@/components/organisms/Cart`)),
  artisanComponent: dynamic(
    () => import(`@/components/organisms/ArtisanComponent`)
  ),
  promoBar: dynamic(() => import(`@/components/molecules/PromoBar`)),
  iframe: dynamic(() => import(`@/components/organisms/Iframe`)),
};

type IBlock =
  | IArtisanComponent
  | IButton
  | IArtisanCarousel
  | IProductCarousel
  | IExternalApps
  | IForm
  | IImageWithTextBlock
  | INewsletter
  | IPopupTimer
  | IRegion
  | ISettings
  | ISlider
  | IVideo
  | IParagraph
  | IAnnouncementBar
  | IAppBar
  | IPromoBar
  | IIframe;

export interface IRest {
  id?: string;
  index?: number;
  bgcolor?: string | null;
  visibility?: 'logged_in' | 'anonimous' | null;
  products?: Product[];
  xselling_productpage?: string[];
  productType?: string;
  pageType?: string;
  currency?: CurrencyCode;
  locales?: ILocales[];
  menuDrawerHandle?: (isOpen: boolean) => void;
}

const createComponents = (block: IBlock, rest?: IRest) => {
  if (
    block?.sys?.contentType?.sys?.id &&
    typeof Components[block.sys.contentType.sys.id] !== 'undefined'
  ) {
    return React.createElement(Components[block.sys.contentType.sys.id], {
      fields: { ...block.fields },
      sys: block.sys,
      ...rest,
    });
  }
  return React.createElement(() => null, {
    key: block?.sys?.contentType?.sys?.id,
  });
};

export default createComponents;
