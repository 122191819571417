import React, { useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import styled, { css } from 'styled-components';
import { Button, TextField } from '@/components/atoms';
import { ICartNoteCmp } from './ICartNoteCmp';

interface IStyleProps {
  showNote?: boolean;
  $cartPage?: boolean;
}

const Note = styled.div<IStyleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${props =>
    !props.$cartPage &&
    css`
      box-shadow: 0px -2px 8px 0px rgba(25, 25, 25, 0.22);
      padding: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
      transition: transform 0.25s ease-in-out;
      z-index: 2;
      background: ${props => props.theme.paletteDefault.white};

      ${p =>
        p.showNote &&
        css`
          transform: translateY(0);
        `};
    `}

  ${props =>
    props.$cartPage &&
    css`
      display: none;
      ${p =>
        p.showNote &&
        css`
          display: block;
        `};
    `}
`;
const NoteHeadingWrapper = styled.div<IStyleProps>`
  ${props =>
    !props.$cartPage &&
    css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    `}

  ${props =>
    props.$cartPage &&
    css`
      display: flex;
      font-size: 16px;
      justify-content: space-between;
    `}
`;

const NoteHeading = styled.div<IStyleProps>`
  font-size: 16px;
  display: block;
  ${p =>
    p.showNote &&
    css`
      display: none;
    `};
`;

const CloseNote = styled.div`
  cursor: pointer;
`;

const CustomButton = styled(Button)<IStyleProps>`
  ${props =>
    !props.$cartPage &&
    css`
      margin-top: 15px;
      z-index: 1;
    `}

  ${props =>
    props.$cartPage &&
    css`
      margin-top: 8px;
    `}
`;

const NoteContainer = styled.div<IStyleProps>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  cursor: pointer;

  ${props =>
    props.$cartPage &&
    css`
      font-size: 16px;
    `}

  ${props =>
    !props.$cartPage &&
    css`
      padding: 16px;
    `}
`;
const NoteWrapper = styled.div`
  width: 100%;
`;

const PencilIconWrapper = styled.div`
  margin-right: 8px;
`;

export const CartNote: React.FC<ICartNoteCmp> = ({
  note,
  saveNote,
  cartPage,
}) => {
  const { t } = useTranslation('common');
  // const cartOpenState = useSelector(
  // 	(state: RootState) => state.cartReducer.isOpen
  // );
  const [cartNote, setCartNote] = useState(note);
  const [showNote, setShowNote] = useState(false);

  const handleNote = (showNote: boolean) => {
    setShowNote(showNote);
  };

  const saveNoteHandler = () => {
    if (cartNote !== note) {
      saveNote(cartNote || '');
    }
    handleNote(false);
  };

  // useEffect(() => {
  // 	if (!cartOpenState) {
  // 		saveNoteHandler();
  // 	}
  // }, [cartOpenState]);

  const heading =
    cartNote && cartNote !== '' ? t('cart.edit-note') : t('cart.add-note');

  return (
    <NoteContainer showNote={showNote} $cartPage={cartPage}>
      <PencilIconWrapper
        onClick={() => {
          handleNote(!showNote);
        }}>
        <Image
          src="/asset/pencil.svg"
          alt={'pencil icon'}
          width={16}
          height={16}
          unoptimized={true}
        />
      </PencilIconWrapper>
      <NoteWrapper>
        <NoteHeading
          showNote={showNote}
          $cartPage={cartPage}
          onClick={() => {
            handleNote(!showNote);
          }}>
          {heading}
        </NoteHeading>
        <Note showNote={showNote} $cartPage={cartPage}>
          <NoteHeadingWrapper $cartPage={cartPage}>
            <div
              onClick={() => {
                handleNote(!showNote);
              }}>
              {heading}
            </div>
            <CloseNote
              onClick={() => {
                setCartNote(note);
                handleNote(false);
              }}>
              <Image
                src="/asset/closeIcon.svg"
                alt={'close icon'}
                width={16}
                height={16}
                unoptimized={true}
              />
            </CloseNote>
          </NoteHeadingWrapper>
          <TextField
            multiline={true}
            rows="5"
            onChange={e => setCartNote(e.target.value)}
            value={cartNote || ''}
            variant="outlined"
            fullWidth={cartPage ? true : false}></TextField>
          <CustomButton
            onClick={saveNoteHandler}
            $cartPage={cartPage}
            variant="outlined">
            {t('cart.save-note')}
          </CustomButton>
        </Note>
      </NoteWrapper>
    </NoteContainer>
  );
};

export default CartNote;
