import React from 'react';
import styled from 'styled-components';
import { Price, MinOrderCounter } from '@/components/molecules';
import { RemoveItem, CartItemImage } from '@/components/atoms';
import { useTranslation } from 'next-i18next';
import { ICartItemRowCmp } from '../ICartItemCmp';

const LineItemNotAvalaibleText = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  color: ${props => props.theme.paletteDefault.error.main};
`;

const LineItemWrapper = styled.div<ILineItemWrapper>`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 16px 30px 16px 8px;

  background-color: ${props =>
    props.alert_bg ? props.theme.paletteDefault.grey1 : 'transparent'};

  border-bottom: ${props =>
    props.alert_bg
      ? `6px solid ${props.theme.paletteDefault.error.main}`
      : 'none'};

  ${props => props.theme.breakpoints.up('md')} {
    padding-top: 24px;
    padding-bottom: 24px;
  }
`;

const PriceWrapper = styled.div`
  font-size: 14px;
  /* margin-bottom: 8px; */
  text-align: right;

  ${props => props.theme.breakpoints.up('md')} {
    width: 60%;
    margin-bottom: 0;
  }
`;

const RemoveItemWrapper = styled.div`
  margin: 0;
`;

const LineItemTitle = styled.div`
  margin-bottom: 4px;
  ${props => props.theme.breakpoints.up('md')} {
    margin-bottom: 0;
    margin-right: 16px;
  }
  font-size: 15px;
  font-weight: 800;
  overflow-wrap: break-word;
`;

const LineItemOptions = styled.div`
  ${props => props.theme.breakpoints.up('md')} {
    margin-bottom: 0;
  }
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  overflow-wrap: break-word;
`;

const FirstColumn = styled.div`
  width: 66%;
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: start;
  }
`;

const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const ThirdColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 96px;
  flex-grow: 1;

  ${props => props.theme.breakpoints.up('tablet')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const Pop = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 66%;
  width: 76%;

  min-width: 112px;

  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: center;
  }
`;
const CartItemImageWrapper = styled.div`
  width: 64px;
  margin-right: 8px;
`;
interface ILineItemWrapper {
  alert_bg: boolean;
}

export const CartItemRow: React.FC<ICartItemRowCmp> = ({
  lineItem,
  currency,
  selectedOptions,
  changeQuantityHandler,
  removeItemHandler,
  error,
}) => {
  const { t } = useTranslation(['common', 'product']);

  if (!lineItem) {
    return null;
  }
  let { variant, title, quantity } = lineItem;
  let totalPrice = variant ? variant?.price?.amount * quantity : null;

  let minimum_order_quantity = lineItem?.variant?.product
    ?.minimum_order_quantity
    ? parseInt(lineItem.variant.product.minimum_order_quantity.value)
    : 1;

  return (
    <LineItemWrapper alert_bg={error}>
      <CartItemImageWrapper>
        <CartItemImage title={title} variant={variant}></CartItemImage>
      </CartItemImageWrapper>

      <Columns>
        <Pop>
          <FirstColumn>
            <LineItemTitle>{title?.toUpperCase()}</LineItemTitle>
            {variant ? (
              <>
                <LineItemOptions>{selectedOptions}</LineItemOptions>
              </>
            ) : (
              <LineItemNotAvalaibleText>
                {t('cart.unavailable-product')}
              </LineItemNotAvalaibleText>
            )}
          </FirstColumn>
          <SecondColumn>
            {variant && (
              <MinOrderCounter
                template="small"
                debounce={true}
                changeQuantityHandler={changeQuantityHandler}
                quantity={quantity}
                error={error}
                minimum_order_quantity={minimum_order_quantity}
              />
            )}
          </SecondColumn>
        </Pop>
        <ThirdColumn>
          {totalPrice && variant && (
            <PriceWrapper>
              <Price
                template="medium"
                prices={[
                  { amount: totalPrice, currencyCode: currency.isoCode },
                ]}
                currency={currency}></Price>
            </PriceWrapper>
          )}
          <RemoveItemWrapper>
            <RemoveItem removeItemHandler={removeItemHandler} />
          </RemoveItemWrapper>
        </ThirdColumn>
      </Columns>
    </LineItemWrapper>
  );
};

export default CartItemRow;
