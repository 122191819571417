import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { Preview } from '@styled-icons/material/Preview';
import Link from 'next/link';

// TODO background not in theme
const CustomPaper = styled(Paper)`
  padding: 16px;
  background: #4caf50;
  a {
    text-decoration: none;
    color: ${props => props.theme.paletteDefault.white};
    margin-left: 8px;
    font-weight: bold;
  }
`;

const PreviewIcon = styled(Preview)`
  color: ${props => props.theme.paletteDefault.white};
`;

const AlertPreview: React.FC = () => {
  return (
    <Snackbar
      open={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}>
      <CustomPaper elevation={6}>
        <PreviewIcon size={28} />
        <Link href="/api/exit-preview">Clicca per uscire dalla preview</Link>
      </CustomPaper>
    </Snackbar>
  );
};

export default AlertPreview;
