import React from 'react';
import { useTheme } from 'styled-components';

const IconPlus: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const theme = useTheme();
  const color1 = theme.icon.color1;
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="m13.36,10.93c-.11,0-.19-.09-.19-.19V3.78c0-.11-.09-.2-.2-.2h-1.91c-.11.01-.2.1-.2.21v6.95c.01.1-.07.19-.18.19H3.87c-.11,0-.2.09-.2.2v1.83c.01.11.1.2.21.2h6.8c.1-.02.19.07.19.18v6.89c0,.11.09.2.2.2h1.91c.11-.01.2-.1.2-.21v-6.88c-.01-.1.07-.19.18-.19h7c.11,0,.2-.09.2-.2v-1.82c-.01-.11-.1-.2-.21-.2h-6.98Z"
        fill={color1}
      />
    </svg>
  );
};

export default IconPlus;
