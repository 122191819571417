import React, { useState, useEffect, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import useDebounce from '@/hooks/useDebounce';
import { TextField, IconPlus, IconMinus } from '@/components/atoms';
import { IQuantityCounterCmp } from './IQuantityCounterCmp';

interface IStyledProps {
  active?: boolean;
  template?: string;
}

const LineItemQtyCont = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${props => props.theme.paletteDefault.primary.main};
  border: 1px solid ${props => props.theme.paletteDefault.primary.main};
  border-radius: 80px;
`;

const LineItemQtyWrapper = styled.div<IStyledProps>`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  ${props =>
    props.template === 'medium' &&
    css`
      font-size: 16px;
      font-weight: 600;
    `}
`;

const LineItemQtyUpd = styled.span<IStyledProps>`
  padding: 0 4px;
  height: 16px;
  ${props =>
    props.template === 'medium' &&
    css`
      font-size: 20px;
      font-weight: normal;
      padding: 0 8px;
    `}
`;

const CustomForm = styled.form`
  display: inline-block;
`;

const CustomInput = styled(TextField)<IStyledProps>`
  max-width: 32px;
  max-height: 32px;

  ${props =>
    props.template === 'medium' &&
    css`
      font-size: 16px;
      max-width: 80px;
      max-height: 80px;
    `}

  input {
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
    font-size: 12px;
    ${props =>
      props.template === 'medium' &&
      css`
        font-size: 16px;
      `}
    text-align: center;
    &:focus {
      border-color: transparent;
    }
    padding: 4px;
  }

  .MuiInputBase-input:focus {
    border: none;
    border-color: transparent;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  .MuiOutlinedInput-root:focus-visible .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
`;

export const QuantityCounter: React.FC<IQuantityCounterCmp> = ({
  debounce,
  template,
  quantity,
  minValue = 1,
  changeQuantityHandler,
}) => {
  const [currentQuantity, setCurrentQuantity] = useState<number | null>(null);
  const debouncedSearchTerm = useDebounce(currentQuantity, debounce ? 750 : 0);

  useEffect(() => {
    if (
      changeQuantityHandler &&
      (debouncedSearchTerm || debouncedSearchTerm === 0)
    ) {
      changeQuantityHandler(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setCurrentQuantity(quantity);
  }, [quantity]);

  const updateQuantity = (qty: number) => {
    if (currentQuantity || currentQuantity === 0) {
      if (currentQuantity + qty >= minValue) {
        setCurrentQuantity(currentQuantity + qty);
      }
    }
  };

  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    if (value || value === '0') {
      setCurrentQuantity(parseInt(value));
    }
    if (value === '' || parseInt(value) < minValue) {
      setCurrentQuantity(null);
    }
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    if (value === '' || value === '0') {
      setCurrentQuantity(quantity);
    }
    if (value !== '' && parseInt(value) < minValue) {
      setCurrentQuantity(minValue);
    }
  };

  const onSubmitHandler = (e: SyntheticEvent) => {
    e.preventDefault();
  };

  return (
    <LineItemQtyCont>
      <LineItemQtyWrapper template={template}>
        <LineItemQtyUpd
          template={template}
          onClick={() => {
            updateQuantity(-1);
          }}>
          <IconMinus width={16} height={16} />
        </LineItemQtyUpd>
        <span>
          <CustomForm onSubmit={onSubmitHandler}>
            <CustomInput
              template={template}
              type="number"
              value={
                currentQuantity || currentQuantity === 0 ? currentQuantity : ''
              }
              onChange={handleChangeQuantity}
              onBlur={onBlurHandler}
              variant={'outlined'}
              size="small"
            />
          </CustomForm>
        </span>
        <LineItemQtyUpd
          template={template}
          onClick={() => {
            updateQuantity(+1);
          }}>
          <IconPlus width={16} height={16} />
        </LineItemQtyUpd>
      </LineItemQtyWrapper>
    </LineItemQtyCont>
  );
};

export default QuantityCounter;
