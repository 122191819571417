import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { AppBar } from '@/components/atoms';
import { CountdownPopupTimer } from '@/components/molecules';
import { IHeaderCmp } from '@/interfaces/index';
import createComponents from '@/utils/createComponents';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { IHeaderFields } from '@/types/contentful/contentful';

const CustomAppBarDiv = styled(AppBar)<ICustomAppBarDiv>`
  padding: 0;
  z-index: 999999999;
  ${props =>
    props.$position === 'sticky' &&
    css`
      position: sticky;
      top: 0;
      border-bottom: 1px solid ${props => props.theme.header.borderColor};
    `};
  ${props =>
    props.$position === 'fixed' &&
    css`
      position: fixed;
      top: 0;
      border-bottom: ${props.$scrollTriggered
        ? `1px solid ${props.theme.header.borderColor}`
        : 'none'};
    `};
  background: transparent;
`;

const MenuOverlay = styled.div`
  background: rgba(0, 0, 0, 0.7);
  min-height: 100%;
  position: fixed;
  width: 100%;
  height: 100vh;
  display: block;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms 0ms;
  z-index: 999;
  &.visible {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 500ms 0ms;
  }
`;

interface ICustomAppBarDiv {
  $position: IHeaderFields['position'];
  $menuDrawerOpen: boolean;
  $scrollTriggered: boolean;
}

const Header: React.FC<IHeaderCmp> = props => {
  const scrollTriggered = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const { locales, locale, currency, section, position = 'sticky' } = props;

  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);

  const showCountdownbar = useSelector(
    (state: RootState) => state.countdownbarReducer.show
  );
  const bannerCountdown = useSelector(
    (state: RootState) => state.countdownbarReducer.bannerCountdown
  );
  const code = useSelector(
    (state: RootState) => state.countdownbarReducer.code
  );

  const isLogged = useSelector(
    (state: RootState) => state.userReducer.isLogged
  );

  const loadingCustomer = useSelector(
    (state: RootState) => state.userReducer.loadingCustomer
  );

  const activePromo = useSelector(
    (state: RootState) => state.userReducer.customer?.activePromo?.value
  );

  const isPromobarVisible = useSelector(
    (state: RootState) => state.uiReducer.promoBar.show
  );

  const menuDrawerHandle = (isOpen: boolean) => {
    setMenuDrawerOpen(isOpen);
  };

  let objProps = {};
  let cont: React.ReactElement[] = [];

  if (section && section.length > 0) {
    section.forEach((block, index) => {
      if (block?.sys?.contentType?.sys?.id === 'appBar') {
        objProps = {
          locales,
          locale,
          currency,
          menuDrawerHandle,
          scrollTriggered,
        };
      }
      const component = (
        <div key={block?.sys?.contentType?.sys?.id + index}>
          {createComponents(block, objProps)}
        </div>
      );

      cont.push(component);
    });
  }

  if (section && section.length > 0) {
    section.map((block: any) => {
      if (
        isLogged &&
        !loadingCustomer &&
        block?.sys?.contentType?.sys?.id === 'promoBar' &&
        activePromo &&
        activePromo === block?.fields?.activePromoName &&
        isPromobarVisible
      ) {
        cont = cont.filter(
          el => !el.key!.toString().startsWith('announcementBar')
        );
      }
    });
  }
  const showCountdown = showCountdownbar && bannerCountdown.fields.enabled;
  return (
    <>
      <CustomAppBarDiv
        $position={position}
        $scrollTriggered={scrollTriggered}
        $menuDrawerOpen={menuDrawerOpen}>
        {showCountdown ? (
          <CountdownPopupTimer code={code} {...bannerCountdown} />
        ) : null}
        {cont}
      </CustomAppBarDiv>

      <MenuOverlay className="overMenu" />
    </>
  );
};

export default Header;
