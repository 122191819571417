const HomePageMarkup = () => {
  if (process.env.NEXT_PUBLIC_B2B === '1') {
    return (
      <script
        className="homePageMarkup"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
						"@context": "https://schema.org",
						"@type": "Organization",
						"name": "MIRTA WHOLESALE",
						"description": "MIRTA WHOLESALE is the Leading Wholesale Marketplace for Made in Italy Brands. Empowering independent luxury retailers to shop wholesale iconic handcrafted products, 100% Made in Italy, straight from the finest Italian luxury artisans. Worldwide free shipping and easy order management with personalized customer service.",
						"url": "https://www.mirta.com/",
						"logo": "${process.env.BASE_DOMAIN_URL}/asset/logo_b2b.png",
						"email": "wholesale@mirta.com",
						"founder": [
							{
								"@type": "Person",
								"name": "Martina Capriotti"
							},
							{
								"@type": "Person",
								"name": "Ciro di Lanno"
					
							}
						]
					}`,
        }}></script>
    );
  }
  return (
    <script
      className="homePageMarkup"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `{
						"@context": "https://schema.org",
						"@type": "Organization",
						"name": "MIRTA",
						"description": "MIRTA is opening the doors of the finest italian leather workshops to the luxury lovers around the world, building a direct link between the artisan and the consumers.",
						"url": "https://www.mirta.com/",
						"logo": "${process.env.BASE_DOMAIN_URL}/asset/logo.svg",
						"email": "hello@mirta.com",
						"sameAs": [
							"https://www.facebook.com/mirtaofficial",
							"https://twitter.com/mirta_official",
							"https://www.pinterest.it/mirtaofficial/",
							"https://www.instagram.com/mirtaofficial/",
							"https://www.linkedin.com/company/mirtaofficial/",
							"https://vimeo.com/mirtaofficial",
							"https://www.wikidata.org/wiki/Q93934800"
						],
						"founder": [
							{
								"@type": "Person",
								"name": "Martina Capriotti"
							},
							{
								"@type": "Person",
								"name": "Ciro di Lanno"
							}
						],
						"contactPoint" : [
							{
								"@type" : "ContactPoint",
								"telephone" : "+39 3381519851",
								"contactType" : "customer service"
							}
						]
					}`,
      }}></script>
  );
};

export default HomePageMarkup;
