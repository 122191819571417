import React from 'react';
import styled from 'styled-components';

const LineItemRemove = styled.div`
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;
interface IRemoveItemCmp {
  removeItemHandler: () => void;
}
export const RemoveItem: React.FC<IRemoveItemCmp> = ({ removeItemHandler }) => {
  return <LineItemRemove onClick={removeItemHandler}>Remove</LineItemRemove>;
};

export default RemoveItem;
