import styled, { css } from 'styled-components';
import { IWrapper } from '@/interfaces/index';

const WrapperExternal = styled.div<IWrapper>`
  padding: 0px clamp(1rem, 0.2353rem + 3.1373vw, 4rem);
  max-width: ${props => {
    return props
      ? props.template === 'FullWidth'
        ? '1920px'
        : '1920px'
      : '1920px';
  }};
  margin: auto;
  ${props =>
    props.withNoPaddingRight &&
    css`
      padding-right: 0 !important;
    `}
  width: 100%;
  background-color: ${props => (props.bgcolor ? props.bgcolor : null)};

  ${props =>
    props.bgcolor &&
    css`
      padding-top: clamp(1rem, 0.8725rem + 0.5229vw, 1rem);
      padding-bottom: clamp(1rem, 0.8725rem + 0.5229vw, 1rem);
    `}//controllare che tipo di componente perche non sempre serve!! vedi faq che hai il bg ma non serve il padding
`;

export default WrapperExternal;
