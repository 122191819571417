import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import useSWR from 'swr';
import { Button } from '@/components/atoms';
import { addToCart } from '@/store/actions/cart';
import { getCartProductCrossSell } from '@/lib/api.product';
import { fetcher } from '@/lib/api';
import { openModal } from '@/store/actions/ui';
import { isCartValid } from '@/utils/Cart';
import { RootState } from '@/store/store';
import { ICartXsellCmp } from './ICartXsellCmp';

interface IStyleProps {
  cartPage: boolean;
}

const CartXsellCont = styled.div<IStyleProps>`
  background-color: ${props => props.theme.cartPage.backgroundCartXsell};
  display: flex;
  line-height: 0;
  align-items: center;
  max-height: 200px;
  padding: 0 16px;

  ${props =>
    props.cartPage &&
    css`
      min-height: 96px;
      padding-left: 8px;
    `}
`;

const XsellImgCont = styled.div<IStyleProps>`
  ${props =>
    props.cartPage &&
    css`
      width: 64px;
      margin: 8px 8px 8px 0;
    `}

  ${props =>
    !props.cartPage &&
    css`
      height: auto;
      max-width: 150px;
      padding: 20px;
      min-width: 150px;
    `}
`;

const XsellInfoCont = styled.div<IStyleProps>`
  line-height: 20px;
  font-size: 14px;

  ${props =>
    !props.cartPage &&
    css`
      min-width: 100%;
      margin-left: 20px;
    `}
`;

const Btn = styled(Button)`
  width: 100%;
  max-width: 80px;
  text-transform: uppercase;
  padding: 5px 0;
`;

export const CartXsell: React.FC<ICartXsellCmp> = ({
  currency,
  cartXsellHandles,
  cartPage,
}) => {
  const { t } = useTranslation('common');
  const isLogged = useSelector(
    (state: RootState) => state.userReducer.isLogged
  );

  const addHandler = () => {
    if (!isLogged && process.env.NEXT_PUBLIC_B2B === '1') {
      dispatch(openModal('logReg', '', 'register'));
    } else {
      let validCart = isCartValid(cart);

      if (validCart) {
        onAddToCart({
          variantId: cartXsell?.variants?.edges[0]?.node?.id,
          quantity: 1,
        });
      } else {
        dispatch(openModal('cartInvalid'));
      }
    }
  };

  const dispatch = useDispatch();
  const onAddToCart = (lineItem: any) => {
    dispatch(addToCart(lineItem, currency, null));
  };
  const cart = useSelector((state: RootState) => state.cartReducer.cart);

  const [productCrosSellMounted, setProductCrosSellMounted] = useState(false);

  useEffect(() => {
    if (cartXsellHandles && cartXsellHandles.length > 0) {
      setProductCrosSellMounted(true);
    }
  }, [cartXsellHandles]);

  const key = getCartProductCrossSell(cartXsellHandles[0] || null);

  const { data } = useSWR(
    productCrosSellMounted ? JSON.stringify(key) : null,
    fetcher,
    { revalidateOnFocus: false, revalidateOnMount: true }
  );

  let cartXsell = null;

  if (data) {
    cartXsell = data.productByHandle;
  }

  // let prices = [];
  // if (
  //   cartXsell &&
  //   cartXsell.variants &&
  //   cartXsell.variants.edges &&
  //   cartXsell.variants.edges[0] &&
  //   cartXsell.variants.edges[0].node &&
  //   cartXsell.variants.edges[0].node.presentmentPrices
  // ) {
  //   prices = cartXsell.variants.edges[0].node.presentmentPrices;
  // }

  return cartXsell && cartXsell.variants ? (
    <CartXsellCont cartPage={cartPage}>
      <XsellImgCont cartPage={cartPage}>
        {cartXsell && cartXsell.variants && cartXsell.variants.edges ? (
          <img
            src={cartXsell.variants.edges[0].node.image.src}
            alt={`${cartXsell.title} product shot`}
          />
        ) : null}
      </XsellImgCont>
      <XsellInfoCont cartPage={cartPage}>
        <div className="Line-item__content-row">
          <div>
            <div>{cartXsell.title}</div>
            <div>
              {/* <Price prices={prices} currency={currency} inCart={true}></Price> */}

              <div>
                <Btn onClick={addHandler}>{t('cart.add')}</Btn>
              </div>
            </div>
          </div>
        </div>
      </XsellInfoCont>
    </CartXsellCont>
  ) : null;
};

export default CartXsell;
