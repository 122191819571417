import React from 'react';
import styled from 'styled-components';
import { MegaMenuContent } from '@/components/molecules';
import { WrapperExternal } from '@/components/atoms';
import { makeLink } from '@/utils/Link';
import { IFooterCmp } from './IFooterCmp';
import { SectionEnum } from '@/types/enums';

const FooterContainer = styled.footer`
  background-color: ${props => props.theme.footer.backgroundColor};
  background-image: url(${props => props.theme.footer.backgroundImage});
  background-position: center;
  background-size: cover;
  color: ${props => props.theme.footer.color};
  border-top: ${props => props.theme.footer.borderWidth}px solid
    ${props => props.theme.footer.borderColor};
  padding-bottom: 24px;
`;

const Copyright = styled.div`
  text-align: center;
  font-size: 12px;
  color: inherit;
`;

const MenuWrapper = styled.div`
  width: 100%;
`;
const LinksWrapper = styled.div``;

const CustomWrapperExternal = styled(WrapperExternal)`
  padding-top: 1px;
`;

const Footer: React.FC<IFooterCmp> = ({ locale, content }) => {
  const { navigation, copyright, socials } = content;

  let socialIcons;
  if (socials && socials.length > 0) {
    socialIcons = socials.flatMap(menu => {
      if (menu.sys?.contentType?.sys?.id === 'imageWithText') {
        let url = '';
        if (
          menu.fields &&
          menu.fields.link &&
          menu.fields?.link?.length > 0 &&
          menu.fields?.link[0]?.fields?.type &&
          menu.fields?.link[0]?.fields?.href
        ) {
          url = makeLink(
            menu.fields?.link[0]?.fields?.type,
            menu.fields?.link[0]?.fields?.href,
            null
          );
        }

        let type = menu.fields?.altTag || '';

        return [
          {
            url,
            type,
          },
        ];
      } else {
        return [];
      }
    });
  }

  return navigation || copyright || socialIcons ? (
    <>
      <FooterContainer>
        <CustomWrapperExternal>
          <LinksWrapper>
            {navigation && (
              <MenuWrapper>
                <MegaMenuContent
                  isvisible={true}
                  groupedLinks={navigation?.fields?.menu}
                  locale={locale}
                  parentName={SectionEnum.FOOTER}
                />
              </MenuWrapper>
            )}
          </LinksWrapper>
        </CustomWrapperExternal>
        {copyright && <Copyright>{copyright}</Copyright>}
      </FooterContainer>
    </>
  ) : null;
};

export default Footer;
