import React from 'react';
import LinkItem from '../LinkItem';
import styled from 'styled-components';
import { IHeadingGroupLinksCmp } from './IHeadingGroupLinksCmp';
import { SectionEnum } from '@/types/enums';
import { Typography } from '@/components/atoms';

const styleMap = {
  [SectionEnum.FOOTER]: {
    container: 'margin-bottom: 14px;',
  },
};

const Container = styled.div<Partial<IHeadingGroupLinksCmp>>`
  text-align: left;
  margin-bottom: 12px;
  ${props => (props.parentName ? styleMap[props.parentName].container : '')}
`;

const HeadingTitle = styled.span`
  &.bigtitle--custom {
    text-transform: uppercase;
  }
  &.titlebig {
    font-size: 22px;
  }
`;

const HeadingGroupLinks: React.FC<IHeadingGroupLinksCmp> = props => {
  const { heading, link, locale, parentLink, parentName } = props;
  let headingTitle = null;

  if (link) {
    const props = {
      fields: {
        ...link.fields,
        text: heading ? heading : link.fields.text,
      },
    };

    headingTitle = (
      <LinkItem
        {...props}
        template="headingLink"
        isheading
        locale={locale}
        parentLink={parentLink}
        parentName={parentName}
      />
    );
  } else {
    headingTitle = (
      <HeadingTitle
        datatype={parentName === 'footer' ? 'subtitle_3' : 'body_1'}
        className={
          parentName === 'footer' ? 'bigtitle bigtitle--custom' : 'titlebig'
        }>
        {heading}
      </HeadingTitle>
    );
  }

  return <Container parentName={parentName}>{headingTitle}</Container>;
};

export default HeadingGroupLinks;
